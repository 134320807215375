import styled, { device, themeGet } from "@styled";

export const FactsWrapper = styled.div`
  background: #f8f8f8;
  padding: 40px 0;
  ${device.medium} {
    padding: 60px 0;
  }
  ${device.large} {
    padding: 80px 0;
  }
`;

export const FactsList = styled.div`
  margin-top: 60px;
`;
export const FactsListInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${device.medium} {
    flex-direction: row;
  }
`;
export const FactsItemLeft = styled.div`
  flex: 1 1 100%;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px 0 0 4px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background: ${themeGet("colors.secondaryDark")};
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
  ${device.medium} {
    flex: 1 1 50%;
    padding: 20px 40px;
    font-size: 22px;
  }
`;
export const FactsItemRight = styled.div`
  flex: 1 1 100%;
  width: 100%;
  padding: 10px 20px;
  border-radius: 0 4px 4px 0;
  background: #caa8ec;
  color: #343434;
  font-size: 18px;
  text-transform: uppercase;
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
  ${device.medium} {
    flex: 1 1 50%;
    padding: 20px 40px;
    font-size: 22px;
  }
`;

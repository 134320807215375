import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-2";
import CaseStudyFacts from "@containers/case-study/facts";
import IntroAreaReverse from "@components/introareareverse/layout-2";
import IntroAreaThree from "@components/introarea/layout-3";
import ImageSection from "@components/ImageBox";

const CaseStudyAirbnb = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Case study - airbnb localization"
      description="We provided user interface translation and localization services to meet the brand’s needs and also adapted Airbnb’s marketing content into Thai." 
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["case-study-airbnb-header"]} />
        <ImageSection data={content["case-study-airbnb-image"]}/>
        <IntroArea data={content["case-study-airbnb-marketplace"]}/>
        <CaseStudyFacts data={content["airbnb-fast-facts"]} />
        <IntroAreaReverse data={content["case-study-airbnb-project-body"]} />
        <IntroAreaThree layout={2} data={content["case-study-airbnb-services"]}/>
        <IntroAreaThree data={content["case-study-airbnb-languages"]} /> 
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudyAirbnbPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "case-studies" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseStudyAirbnb.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseStudyAirbnb;

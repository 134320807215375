import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import { SectionTitleType, MottoType, ItemType } from "@utils/types";
import {
  FactsWrapper,
  FactsList,
  FactsListInner,
  FactsItemLeft,
  FactsItemRight,
} from "./style";
import { Container, Row, Col } from "react-bootstrap";

const CaseStudyFacts = ({ data }) => {
  return (
    <FactsWrapper>
      <Container>
        <Row>
          <Col>
            {data?.section_title && (
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <FactsList>
              {data &&
                data.items.map((el, index) => (
                  <FactsListInner key={index}>
                    <FactsItemLeft>{el.title}</FactsItemLeft>
                    <FactsItemRight>{el.description}</FactsItemRight>
                  </FactsListInner>
                ))}
            </FactsList>
          </Col>
        </Row>
      </Container>
    </FactsWrapper>
  );
};

CaseStudyFacts.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    motto: PropTypes.shape(MottoType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default CaseStudyFacts;
